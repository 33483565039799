export const menuItems = [
  {
    path: "/",
    text: "Inicio",
  },
  {
    path: "/products",
    text: "Productos",
  },
  // {
  //   path: "/news",
  //   text: "news",
  // },
  {
    path: "/contact",
    text: "Contactanos",
  },
]
