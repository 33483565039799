export const menuItems = [
  {
    path: "/",
    text: "Inicio",
  },
  {
    path: "/about",
    text: "Nosotros",
  },
  // {
  //   path: "/news",
  //   text: "news",
  // },
  {
    path: "/contact",
    text: "Contactanos",
  },
]
